<template>
  <common>
    <div class="registrationSuccess">
      <img src="./img/success.png" alt="" />
      <span>您已报名成功</span>
      <div class="footer-btn">
        <div class="btn apply-btn" @click="redirect('joinActivity')">
          继续报名
        </div>
        <div class="btn back" @click="redirect('guidePage')">返回首页</div>
      </div>
    </div>
  </common>
</template>

<script>
import common from "../components/common.vue";
export default {
  name: "registrationSuccess",
  components: {
    common,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    redirect(name) {
      this.$router.replace({
        name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.registrationSuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 334px;
    height: 301px;
  }
  span {
    margin-top: 35px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #1874fd;
    line-height: 33px;
    font-style: normal;
  }
  .footer-btn {
    display: flex;
    justify-content: center;
    .btn {
      margin-top: 38px;
      width: 239px;
      height: 50px;
      line-height: 50px;
      background: #1874fd;
      border-radius: 6px;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      font-style: normal;
      box-sizing: border-box;
      &:first-child {
        margin-right: 138px;
      }
    }
    .back {
      color: #1874fd;
      background-color: #fff;
      border: 1px solid #1874fd;
    }
  }
}
</style>
